<template>
<div class="mt-8 mx-8" >
  <v-data-table
    :headers="headers"
    :items="datasets"
    sort-by="name"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat >
        <v-toolbar-title>DATABASES </v-toolbar-title>
        <v-divider class="mx-4" inset vertical ></v-divider>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="600px" >
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on"  >
              <v-icon class="pr-2">mdi-database-plus</v-icon>New Database
            </v-btn>
          </template>
          <v-card>
            <v-card-title class="accent">
              <span  class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col  >
                    <v-text-field  v-model="selectedDataset.name" label="Database Name" ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4"  >
                    <v-select v-model="selectedDataset.datasetType" :items="datasetTypes" item-text="name" item-value="id" label="Database Type"    ></v-select>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close" >
                Cancel
              </v-btn>
              <v-btn color="blue darken-1" text @click="save" >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="accent">
              <span  class="text-h5">Delete Database </span>
            </v-card-title>            
            <v-card-text class="body-1 mt-6 ">Are you sure you want to delete <b>{{selectedDataset.name}}</b> database?</v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary darken-1" text @click="closeDelete">Cancel</v-btn>
              <v-btn color="red darken-1" text @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
    <router-link :to="{name:'datasetInfo', params:{id: item.id}}" class="text-decoration-none" >
      <v-tooltip top color="primary">
        <template v-slot:activator="{ on }">
            <v-btn
              icon
              large
              color="primary"
              @click="editItem(item)" 
              v-on="on" 
            >
              <v-icon class="mr-2">mdi-database-cog</v-icon>
            </v-btn>          
        </template>
        <span> Manage {{item.name}}</span>
    </v-tooltip>
    </router-link>

      <v-tooltip top color="orange">
        <template v-slot:activator="{ on }">
            <v-btn
              icon
              large
              color="orange"
              @click="deleteItem(item)" 
              v-on="on" 
            >
              <v-icon class="mr-2">mdi-delete-forever</v-icon>
            </v-btn>          

        </template>
        <span>Delete {{item.name}}</span>
      </v-tooltip>



    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="initialize" >
        Reset
      </v-btn>
    </template>
  </v-data-table>
</div>
</template>

<script>
  import { getDatasets, getDatasetTypes, addDataset,deleteDataset } from '@/api/dataset.js'
  export default {
    data: () => ({
      datasetTypes: [],
      dialog: false,
      dialogDelete: false,
      headers: [
        { text: 'NAME', align: 'start', value: 'name' ,class:"accent h2"},
        { text: 'TYPE', value: 'datasetType.name' ,class:"accent"},
        { text: 'STATUS', value: 'status.name',class:"accent" },
        { text: 'LAST IMPORT', value: 'importedAt' ,class:"accent"},
        { text: 'FILE', value: 'fileName' ,class:"accent"},
        { text: 'FILE SIZE', value: 'fileSize' ,class:"accent"},
        { text: 'ACTIONS', divider:true, value: 'actions', sortable: false ,class:"accent"},
      ],
      datasets: [],
      editedIndex: -1,
      selectedDataset: {
        name: '',
        datasetType: 1
      },
      defaultItem: {
        name: '',
        datasetType: 1
      },
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'New Database' : 'Edit Database'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created () {
      this.initialize()
    },

    methods: {
      loadDatasets(){
        const _this = this

        getDatasets(function(res){
          _this.datasets = res.map(ds => {
            ds.uploadedAt = (ds.uploadedAt !== null ? new Date(ds.uploadedAt).toLocaleString() : '')
            ds.importedAt = (ds.importedAt !== null ? new Date(ds.importedAt).toLocaleString() : '')
            if ( ds.fileSize > 1024*1024 ){
              ds.fileSize =  Math.round((ds.fileSize/1024/1024)*100)/100 + ' MB'
            } else if ( ds.fileSize > 1024 ){
              ds.fileSize =  Math.round((ds.fileSize/1024)*10)/10 + ' KB'
            } else if (ds.fileSize !== null ){
              ds.fileSize = ds.fileSize + ' Bytes'
            }
            return ds
          })
        })        
      },
      initialize () {
        const _this = this
        getDatasetTypes(function(res){
          _this.datasetTypes = res
        })
        this.loadDatasets()
      },

      editItem (item) {
        this.selectedDataset = item
        this.dialog = true
      },

      deleteItem (item) {
        console.log(item)
        this.selectedDataset = item
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        const _this = this
        deleteDataset(this.selectedDataset.id,function(res){
          console.log(res)
          _this.loadDatasets()
        }, 'Database ' + _this.selectedDataset.name + " removed successfully" )
        this.closeDelete()
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.selectedDataset = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.selectedDataset = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        if (this.editedIndex > -1) {
          Object.assign(this.datasets[this.editedIndex], this.selectedDataset)
        } else {
          const _this = this
          addDataset(this.selectedDataset, function(res){
            console.log(res)
            _this.loadDatasets()
          }, 'Database ' + _this.selectedDataset.name +  ' added successfully'
          )
        }
        this.close()
      },
    },
  }
</script>